import { useState, useEffect, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

import SettingContext from "Providers/SettingContext";
import Language from "Routes/temperatureMonitoring/lang/Language";
import Scales from "Routes/temperatureMonitoring/scales/Scales";

import { AppId } from "Components/applications/ApplicationData";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        top: "-4px",
    },
})((props) => (
    <Menu
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        disableScrollLock={true}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function Setting(props) {
    const setting = useContext(SettingContext);
    const appId = setting.globalSetting.appId;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // when the setting is showing body's scrollbar disappears. so to prevent disappear the scrollbar
        // document.body.style.overflow = "scroll";
        // document.body.style.paddingRight = "0px";
    });

    if (
        appId == AppId.TEMPERATURE_MONITORING ||
        appId == AppId.WATER_MANAGEMENT ||
        appId == AppId.DEVICE_SCAN ||
        appId == AppId.SMART_AGRICULTURE ||
        appId == AppId.EMERA
    ) {
        return (
            <>
                <IconButton aria-label="delete" onClick={handleClick} size="large">
                    <i className="fa fa-cog" aria-hidden="true" style={{ color: "white" }}></i>
                </IconButton>
                <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} style={{ marginTop: "7px" }}>
                    <div className="container m-1 p-0" style={{ width: "150px", textAlign: "center" }}>
                        {/* <div className="row" style={{ margin: '0px', padding: '0px', width: '100%', height: '40px' }}>
                        <Language isEnglish={true}></Language>
                    </div> */}
                        <div className="row" style={{ margin: "0px", padding: "0px", width: "100%", height: "40px" }}>
                            <Scales isCelsius></Scales>
                        </div>
                    </div>
                </StyledMenu>
            </>
        );
    } else {
        return null;
    }
}
