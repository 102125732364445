import ApiService from "Services/apiService";

const SubscriptionService = {
    // addSubscription: async (selectedCompanyId, appId, start, end, appIds) => {
    //     let requestOptions = {
    //         url: `/v2/companies/subscription`,
    //         data: {
    //             companyId: selectedCompanyId,
    //             appId: appId,
    //             start: start,
    //             end: end,
    //             appIds: appIds
    //         },
    //     };
    //     // console.log(requestOptions);

    //     const result = await ApiService.putData(requestOptions);
    //     // console.log(result);
    //     if (result.code && result.code === "failure") {
    //         throw result.message;
    //     }

    //     return result;
    // },

    renew: async (subId, companyId, appId) => {
        let requestOptions = {
            url: `/v2/companies/renew_subscription`,
            data: {},
        };

        // console.log(companyId);

        if (subId) {
            requestOptions.data["id"] = subId;
        } else if (companyId) {
            requestOptions.data["companyId"] = companyId;
            requestOptions.data["appId"] = appId;
        }

        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result.code && result.code === "failure") {
            throw result.message;
        }

        return result;
    },
};

export default SubscriptionService;
