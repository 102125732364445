import React, { useState, Suspense, useEffect } from "react";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Header from "./Header";
import style from "./style.module.css";
import { Auth } from "aws-amplify";
import validator from "validator";
import Button from "@mui/material/Button";

function LoginPage() {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // first login
    const [firstLoginUser, setFirstLoginUser] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [info, setInfo] = useState();

    useEffect(() => {
        // console.log("uef in LoginPage");
    }, []);

    const submit = (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        Auth.signIn({ username: email, password: password })
            .then((user) => {
                // console.log(user);
                if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
                    setLoading(false);
                    setFirstLoginUser(user);
                } else {
                    // sucesss
                    setLoading(false);
                    history.push("/apps");
                }
            })
            .catch((e) => {
                // console.eror(e);
                setLoading(false);
                setError("Incorrect username or password");
            });
    };

    const handleForgot = () => {
        // console.log("email" + email);
        if (validator.isEmail(email)) {
            Auth.forgotPassword(email, {})
                .then((u) => {
                    history.push(`/forgot/${encodeURIComponent(email)}`);
                })
                .catch((e) => {
                    console.error(e);
                    setError(t("login.user-notfound"));
                });
        } else {
            setError(t("login.invalid-email"));
        }
    };

    const handleReset = () => {
        if (validator.isEmpty(newPassword)) {
            setError("login.password-cannottbe-empty");
            return;
        } else if (newPassword != confirmPassword) {
            setError(t("login.passward-not-same"));
            return;
        }

        Auth.completeNewPassword(firstLoginUser, newPassword)
            .then((u) => {
                // sucesss
                setLoading(false);
                setFirstLoginUser(undefined);
            })
            .catch((e) => {
                // console.log(e);
                setInfo(t("login.pssword-policy"));
            });
    };

    return (
        <div className={style.background}>
            <Box sx={{ backgroundColor: "white", width: "400px", border: "1px solid grey" }}>
                <Suspense fallback={<span />}>
                    <Header />
                </Suspense>

                {!firstLoginUser && (
                    <Box sx={{ p: 10 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center", width: "100%" }}>
                                <img src="/apps/logoHapy3.png" alt="Hapy Services" width="300px" height="300px" />
                            </Box>
                        </Box>
                        {/* <h1>{t('login.connect')}</h1> */}
                        <Stack component="form" spacing={2} onSubmit={submit}>
                            <TextField
                                variant="standard"
                                label={t("login.email")}
                                type="email"
                                fullWidth
                                required
                                disabled={loading}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.password")}
                                type="password"
                                fullWidth
                                required
                                value={password}
                                disabled={loading}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                            {error && (
                                <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                    {error}
                                </Alert>
                            )}
                            <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>
                                {t("login.login")}
                            </LoadingButton>
                        </Stack>
                        <Box sx={{ mt: 3 }} textAlign="center">
                            <a href="#" onClick={handleForgot}>
                                {t("login.forgot-password")}
                            </a>
                        </Box>
                        <Box sx={{ mt: 3, display: "flex", flexDirection: "row", justifyContent: "center" }} textAlign="center">
                            <a href="https://hapyservices.com/en/" alt="hapyservices site" target="_blank" rel="noopener noreferrer">
                                Hapy Services
                            </a>
                            <Box sx={{ mx: 2 }} />
                            <a href="https://hapyservices.com/en/contact-us/" alt="hapyservices site" target="_blank" rel="noopener noreferrer">
                                Need support?
                            </a>
                        </Box>
                    </Box>
                )}

                {firstLoginUser && (
                    <Box sx={{ p: 10 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center", width: "100%" }}>
                                <img src="/apps/logoHapy3.png" alt="Hapy Services" width="300px" height="300px" />
                            </Box>
                        </Box>
                        <h4>{t("login.change-password")}</h4>
                        <Stack component="form" spacing={2} onSubmit={submit}>
                            <TextField
                                variant="standard"
                                label={t("login.password")}
                                type="password"
                                fullWidth
                                required
                                value={newPassword}
                                disabled={loading}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.enter-password-again")}
                                type="password"
                                fullWidth
                                required
                                value={confirmPassword}
                                disabled={loading}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                            <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                {error}
                            </Alert>
                            {/* <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>{t('login.login')}</LoadingButton> */}
                            <Alert severity="info" sx={{ opacity: info ? 1 : 0 }}>
                                {info}
                            </Alert>

                            <Button variant="contained" onClick={handleReset}>
                                {t("login.send")}
                            </Button>
                        </Stack>
                    </Box>
                )}
            </Box>
        </div>
    );
}

export default LoginPage;
