import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import UserService from "Services/userService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import moment from "moment";
import UserMenuService from "./UserMenuService";
import "Locales/i18n";
import { Auth } from "aws-amplify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UtilService from "Services/utilService";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function UserMenu() {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [userInfo, setUserInfo] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment());
    const [cognitoUserName, setCognitoUserName] = useState();
    const [language, setLanguage] = useState("en");

    useEffect(() => {
        // when the setting is showing body's scrollbar disappears. so to prevent disappear the scrollbar
        // document.body.style.overflow = "scroll";
        // document.body.style.paddingRight = "0px";

        if (userInfo && userInfo.length == 0) {
            UserService.getAuthenticatedUser().then((user) => {
                // console.log(user);

                if (user && Object.keys(user).length !== 0) {
                    let temp = [];
                    let tempName = "";
                    if (user.firstName) {
                        tempName = user.firstName;
                    }
                    if (user.lastName) {
                        tempName = tempName + " " + user.lastName;
                    }

                    temp.push(toObj(tempName, false));
                    temp.push(toObj(user.email, false));
                    temp.push(toObj(user.company, false));

                    let roles = user.authorities.split(",");
                    let securityRole = roles.filter((r) => !r.startsWith("APPLICATION_") && !r.startsWith("POWER_")).join(",");

                    temp.push(toObj(securityRole, false));

                    if (user.lastSubscription) {
                        let oneMonthAfter = dayjs().add(1, "month").hour(23).minute(59).second(59);
                        let endDate = dayjs(user.lastSubscription.endDate * 1000);

                        // console.log("One month after :" + oneMonthAfter.toISOString());
                        // console.log("End of date : " + endDate.toISOString());
                        // console.log(endDate.isAfter(oneMonthAfter));

                        if (endDate.isAfter(oneMonthAfter)) {
                            temp.push(toObj(UtilService.formatYearMonthDay(user.lastSubscription.endDate * 1000), true));
                        } else {
                            temp.push(toObj(UtilService.formatYearMonthDay(user.lastSubscription.endDate * 1000), true, "red"));
                        }
                    }

                    setUserInfo(temp);

                    setCognitoUserName(user.cognitoUserName);
                    setLanguage(user.locale);

                    i18n.changeLanguage(user.locale);
                }
            });
        }

        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    });

    const toObj = (text, isSub, color) => {
        let obj = { text: text, isSub: isSub };
        if (color) {
            obj["color"] = color;
        }
        return obj;
    };

    const handleClick = (event) => {
        // console.log("handleClick...");
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        // console.log("handleClose");
        setAnchorEl(null);
    };

    const handleMenu = () => {
        Auth.signOut({})
            .then(() => (window.location.href = "/apps"))
            .catch((e) => console.log(e));
    };

    const handleLanguage = (e) => {
        const lang = e.target.value;
        setLanguage(lang);

        UserMenuService.updateUserLanguage(cognitoUserName, lang).then(() => {
            i18n.changeLanguage(lang);
        });
    };

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Account">
                    <IconButton
                        onClick={handleClick}
                        // size="small"
                        // sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                        <i className="fa fa-user-circle-o" aria-hidden="true" style={{ color: "white" }}></i>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem>
                    <List>
                        {userInfo &&
                            userInfo.map((m, idx, arr) => {
                                // console.log(m);

                                if (idx + 1 === arr.length && m.isSub) {
                                    return (
                                        <React.Fragment key={idx}>
                                            <ListItem key={"local_time"} sx={{ paddingTop: 0, paddingBottom: 0, width: "100%", gap: 1 }}>
                                                <ListItemText primary={t("label.your_time")} sx={{ width: 130 }} />
                                                <ListItemText primary={currentTime.format("HH:mm")} sx={{ width: 100 }} />
                                            </ListItem>
                                            <ListItem key={m.text} sx={{ paddingTop: 0, paddingBottom: 0, gap: 1 }}>
                                                <ListItemText primary={t("label.sub_end")} sx={{ color: m.color ? "red" : "black", width: 130 }} />
                                                <ListItemText primary={m.text} sx={{ color: m.color ? "red" : "black", width: 100 }} />
                                            </ListItem>
                                        </React.Fragment>
                                    );
                                } else if (idx + 1 === arr.length && !m.isSub) {
                                    return (
                                        <React.Fragment key={idx}>
                                            <ListItem key={m.text} sx={{ paddingTop: 0, paddingBottom: 0, gap: 1 }}>
                                                <ListItemText primary={m.text} sx={{ color: m.color ? "red" : "black" }} />
                                            </ListItem>
                                            <ListItem key={"local_time"} sx={{ paddingTop: 0, paddingBottom: 0, width: "100%", gap: 1 }}>
                                                <ListItemText primary={t("label.your_time")} sx={{ width: 120 }} />
                                                <ListItemText primary={currentTime.format("HH:mm")} sx={{ width: 100 }} />
                                            </ListItem>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <ListItem key={m.text} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <ListItemText primary={m.text} sx={{ color: m.color ? "red" : "black" }} />
                                        </ListItem>
                                    );
                                }
                            })}

                        {/* <ListItem key={"local_time"} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            <ListItemText primary={currentTime.format("YYYY-MM-DD HH:mm:ss")} />
                        </ListItem> */}
                        {/* <ListItem key={"language"} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
                                <ListItemText>French</ListItemText>
                                <CustomSwitch checked={language == "en" ? true : false} onChange={(e) => handleLanguage(e)} />
                                <ListItemText>English</ListItemText>
                            </Box>
                        </ListItem> */}
                        <ListItem key={"language"} sx={{ paddingTop: 0, paddingBottom: 0, mt: 1 }}>
                            <ToggleButtonGroup
                                value={language}
                                exclusive
                                onChange={(e) => handleLanguage(e)}
                                aria-label="text alignment"
                                sx={{ width: "100%" }}
                            >
                                <ToggleButton value="fr" aria-label="left aligned" size="small" sx={{ width: "50%" }}>
                                    French
                                </ToggleButton>
                                <ToggleButton value="en" aria-label="centered" size="small" sx={{ width: "50%" }}>
                                    English
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ListItem>
                    </List>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleMenu}>
                    <ListItemIcon>
                        {/* <Logout fontSize="small" /> */}
                        <i className="fa fa-sign-out" aria-hidden="true" style={{ fontSize: "25px" }}></i>
                    </ListItemIcon>
                    {t("label.logout")}
                </MenuItem>
            </Menu>
        </>
    );
}
