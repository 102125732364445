import ApiService from "Services/apiService";
import _ from "underscore";

const UserService = {
    user: {},

    getAuthenticatedUser: async () => {
        let user = await ApiService.getReady();
        let groups = user.groups;

        let userJson = {};

        if (groups) {
            let arryGroup = [];

            if (groups.includes("SUPER_ADMIN")) {
                userJson["authorities"] = "SUPER_ADMIN";
            } else if (groups.includes("CLIENT_ADMIN")) {
                userJson["authorities"] = "CLIENT_ADMIN";
            } else {
                userJson["authorities"] = "USER";
            }

            for (let i = 0; i < groups.length; i++) {
                if (groups[i].startsWith("COMPANY_")) {
                    userJson["company"] = groups[i].substring(8);

                    const companyInfo = await UserService.requestCompanyByName(groups[i].substring(8));
                    // console.log(companyInfo);

                    if (companyInfo.isGrandChildrenCompany) userJson["isGrandChildrenCompany"] = companyInfo.isGrandChildrenCompany;
                    if (companyInfo.isRootCompany) userJson["isRootCompany"] = companyInfo.isRootCompany;
                    if (companyInfo.companyId) userJson["companyId"] = companyInfo.companyId;
                    if (companyInfo.lastSubscription) userJson["lastSubscription"] = companyInfo.lastSubscription;
                }

                if (groups[i].startsWith("APPLICATION_")) {
                    arryGroup.push(groups[i]);
                }

                if (groups[i] == "CLIENT_DASHBOARD") {
                    userJson["client_dashboard"] = true;
                }
            }

            if (!_.isEmpty(arryGroup)) {
                userJson["application"] = arryGroup;
            }

            userJson["username"] = user.username;
            userJson["cognitoUserName"] = user.username; // For Favorite Attributes
            userJson["zoneinfo"] = user.zoneinfo;
            userJson["firstName"] = user.firstName;
            userJson["lastName"] = user.lastName;
            userJson["email"] = user.email;
            userJson["locale"] = user.locale;
        }

        return userJson;
    },

    requestCompanyByName: async (name) => {
        let requestOptions = {
            url: encodeURI(`/api/companies/${name}/name`),
        };

        try {
            const result = await ApiService.getData(requestOptions);
            // console.log(result);

            // if (result.parent && result.parent.parent) {
            //     return true;
            // }

            const mapped = {
                isGrandChildrenCompany: result.parent && result.parent.parent ? true : false,
                isRootCompany: result.parent ? false : true,
                companyId: result.id,
            };
            // console.log(mapped)

            if (result.lastSubscription) {
                mapped["lastSubscription"] = result.lastSubscription;
            }

            return mapped;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    },
};

export default UserService;
