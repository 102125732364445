import { useEffect, useState } from "react";
import ItemList from "Components/applications/ItemList";
import UserService from "Services/userService";
import { AppId } from "./ApplicationData";
import _ from "underscore";
import { useHistory } from "react-router-dom";

const ApplicationList = ({ applications }) => {
    const [loaded, setLoaded] = useState(false);
    const [userApplications, setUserApplications] = useState([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if (!loaded) {
            UserService.getAuthenticatedUser().then((user) => {
                switch (user.authorities) {
                    case "SUPER_ADMIN":
                        setIsSuperAdmin(true);
                        break;
                    default:
                        break;
                }

                if (user.application) {
                    const user_apps = user.application.map((a) => {
                        switch (a) {
                            case "APPLICATION_TEMPERATURE_MONITORING":
                                return AppId.TEMPERATURE_MONITORING;
                            case "APPLICATION_WATER_MANAGEMENT":
                                return AppId.WATER_MANAGEMENT;
                            case "APPLICATION_DEVICE_SCAN":
                                return AppId.DEVICE_SCAN;
                            case "APPLICATION_SMART_AGRICULTURE":
                                return AppId.SMART_AGRICULTURE;
                            case "APPLICATION_EMERA":
                                return AppId.EMERA;
                            case "APPLICATION_DEVICE_EXPLORER":
                                return AppId.DEVICE_EXPLORER;
                            case "APPLICATION_BIZ":
                                return AppId.BIZ;
                            case "APPLICATION_SUPERVIOT":
                                return AppId.SUPERVIOT;
                            case "APPLICATION_ROBOT":
                                return AppId.ROBOT;
                            case "APPLICATION_RIOT":
                                return AppId.RIOTAPP;
                            default:
                                break;
                        }
                    });

                    // If a user has only one application, forward the page
                    if (user_apps && user_apps.length == 1) {
                        switch (user_apps[0]) {
                            case AppId.TEMPERATURE_MONITORING:
                                history.push(`/apps/temperature_monitoring`);
                                break;
                            case AppId.WATER_MANAGEMENT:
                                history.push(`/apps/water_management`);
                                break;
                            case AppId.DEVICE_SCAN:
                                history.push(`/apps/device_scan/scan`);
                                break;
                            case AppId.SMART_AGRICULTURE:
                                history.push(`/apps/smart_agriculture`);
                                break;
                            case AppId.EMERA:
                                history.push(`/apps/emera/v2`);
                                break;
                            case AppId.DEVICE_EXPLORER:
                                history.push(`/apps/device_explorer/device_explorer/main`);
                                break;
                            case AppId.BIZ:
                                history.push(`/apps/biz`);
                                break;
                            case AppId.SUPERVIOT:
                                history.push(`/apps/superviot`);
                                break;
                            case AppId.ROBOT:
                                history.push(`/apps/robot`);
                                break;
                            case AppId.RIOTAPP:
                                history.push(`/apps/riotapp`);
                                break;
                            default:
                                break;
                        }
                    }

                    setUserApplications(user_apps);
                }

                setLoaded(true);
            });
        }
    }, [userApplications]);

    return (
        <>
            {loaded && (
                <ItemList
                    elems={applications.filter((application) => filterAvailableApplications(application, userApplications, isSuperAdmin))}
                    getTitle={getTitle}
                    getUrl={getUrl}
                />
            )}
        </>
    );
};

const filterAvailableApplications = (application, userApplications, isSuperAdmin) => {
    // return userApplications.length === 0 || userApplications.hasOwnProperty(application.id)
    if (application.id == AppId.BIZ && !isSuperAdmin) {
        return false;
    }

    return userApplications.length === 0 || _.contains(userApplications, application.id);
};

const getTitle = (application) => {
    return application.title;
};

const getUrl = (application) => {
    return `${process.env.PUBLIC_URL}${application.path}`;
};

export default ApplicationList;
